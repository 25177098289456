<template>
    <input
        type="number"
        :placeholder="placeholder"
        v-model.number.lazy="field.$model"
        :disabled="disabled"
        :class="{ 'form-control': true, 'is-invalid': field.$error }"
    />
</template>

<script>
    export default {
        props: {
            field: { type: Object, required: true },
            placeholder: { type: String, required: false },
            disabled: { type: Boolean, required: false, default: false }
        }
    };
</script>

<style lang="scss" scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
