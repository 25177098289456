<template>
    <p v-if="!NETS_IS_PROD">
        test card data:
        <a href=" https://developers.nets.eu/nets-easy/en-EU/docs/test-card-processing/" target="_blank">
            https://developers.nets.eu/nets-easy/en-EU/docs/test-card-processing/
        </a>
    </p>

    <div :id="containerId"></div>
</template>

<script setup>
    // https://developers.nets.eu/nets-easy/en-EU/api/checkout-js-sdk/
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import loadScript from '../../utility/load-script';
    import timeout from '../../utility/timeout';
    import getNetsLocale from './nets-locales';

    const props = defineProps({
        paymentId: {
            type: String,
            required: true
        },
        scrollIntoView: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const emit = defineEmits(['onSuccess']);
    const store = useStore();

    const checkoutJsEnvs = {
        test: 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1',
        prod: 'https://checkout.dibspayment.eu/v1/checkout.js?v=1'
    };

    const NETS_CHECKOUT_KEY = store.getters['appSettings/NETS_CHECKOUT_KEY'];
    const NETS_IS_PROD = store.getters['appSettings/NETS_IS_PROD'];
    const NETS_LOCALE = getNetsLocale(store.getters['appSettings/APP_LOCALE']);

    const containerId = ref(`netseasy-wrapper-${props.paymentId}`);

    onMounted(() => {
        loadScript(NETS_IS_PROD ? checkoutJsEnvs.prod : checkoutJsEnvs.test)
            .then(() => {
                const checkout = new Dibs.Checkout({
                    checkoutKey: NETS_CHECKOUT_KEY,
                    paymentId: props.paymentId,
                    containerId: containerId.value,
                    language: NETS_LOCALE,
                    theme: {
                        backgroundColor: 'transparent'
                    }
                });
                checkout.on('payment-completed', () => emit('onSuccess'));
            })
            .then(() => timeout(3000)) // some "safe" value until iframe content is rendered so sroll has enough room to occur
            .then(() => props.scrollIntoView && document.getElementById(containerId.value)?.scrollIntoView());
    });
</script>

<style lang="scss" scoped>
    ::v-deep(iframe) {
        min-height: unset !important;
    }
</style>
