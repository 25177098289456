const scriptCache = {};

const loadScript = (url) => {
    if (!scriptCache[url]) {
        scriptCache[url] = new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = url;
            script.onload = () => resolve();
            document.head.appendChild(script);
        });
    }

    return scriptCache[url];
};

export default loadScript;
