// https://developers.nets.eu/nets-easy/en-EU/api/#localization

const getNetsLocale = (appLocale) => {
    switch (appLocale) {
        case 'Uk':
            return 'en-GB';

        case 'Dk':
            return 'da-DK';

        case 'No':
            return 'nb-NO';

        case 'Sv':
            return 'sv-SE';

        default:
            return 'en-GB';
    }
};

export default getNetsLocale;
