<template>
    <Spinner v-if="INITIATING_PAYMENT" :overlayParent="true" />

    <ErrorMessage :message="INITIATION_ERROR" />

    <h1>{{ t('postpay_overview.form_title') }}</h1>
    <p class="mb-3 mb-sm-4">{{ t('postpay_overview.form_sub_title') }}</p>

    <form novalidate @submit.prevent="submitForm()" class="col-sm-8 offset-sm-2 text-center">
        <div class="input-group mb-3 test-postpayinit-amount">
            <span class="input-group-text test-postpayinit-currency">{{ BALANCE_CURRENCY }} </span>
            <NumberInput :field="v$.amount" :placeholder="t('postpay_overview.amount_hint')" :disabled="disableForm" />
            <ValidationError
                :formModel="v$.amount"
                type="required"
                :message="t('postpay_overview.amount_val_required')"
            />
            <ValidationError
                :formModel="v$.amount"
                type="betweenWithFormat"
                :message="t('postpay_overview.amount_val_invalid')"
            />
        </div>

        <div class="mb-4 test-postpayinit-mailbox" v-if="emailFieldAvailable">
            <TextInput :field="v$.email" :placeholder="t('postpay_overview.email_hint')" :disabled="disableForm" />
            <ValidationError
                :formModel="v$.email"
                type="required"
                :message="t('postpay_overview.email_val_required')"
            />
            <ValidationError :formModel="v$.email" type="email" :message="t('postpay_overview.email_val_invalid')" />

            <div v-if="!newsletterAccepted" class="mt-1 text-start">
                <CheckboxInput
                    :field="v$.newsletterConsent"
                    :label="t('postpay_overview.subscribe_to_newsletter')"
                    :id="newsletterCheckbox"
                    :disabled="disableForm"
                />
            </div>
        </div>

        <button class="btn btn-primary msd-form-submit" :disabled="disableForm">
            {{ t('postpay_overview.submit') }}
        </button>
    </form>
    <div v-if="!disableForm" class="mt-3 mb-3">
        <img :src="postPaymentEngineLogo" class="msd-payment-logo" />
    </div>
</template>

<script setup>
    import { computed, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import useVuelidate from '@vuelidate/core';
    import { required, between, email, and } from '@vuelidate/validators';
    import TextInput from '../../components/VuelidateForms/TextInput.vue';
    import NumberInput from '../../components/VuelidateForms/NumberInput.vue';
    import CheckboxInput from '../../components/VuelidateForms/CheckboxInput.vue';
    import ValidationError from '../../components/VuelidateForms/ValidationError.vue';
    import Spinner from '../../components/Spinner.vue';
    import ErrorMessage from '../../components/ErrorMessage.vue';
    import paymentEngines from './payment-engines';
    import helpers from '../../utility/helpers';

    const props = defineProps({
        disableForm: { type: Boolean, required: false, default: false }
    });
    const emit = defineEmits(['onSubmit']);

    const store = useStore();
    const { t } = useI18n();

    const BALANCE_AMOUNT = store.getters['balancePostpay/BALANCE_AMOUNT'];
    const BALANCE_CURRENCY = store.getters['balancePostpay/BALANCE_CURRENCY'];
    const INITIATING_PAYMENT = computed(() => store.getters['pagePostpayOverview/INITIATING_PAYMENT']);
    const INITIATION_ERROR = computed(() => store.getters['pagePostpayOverview/INITIATION_ERROR']);
    const emailFieldAvailable = ref(store.getters['appSettings/POST_PAYMENT_ENGINE'] === paymentEngines.Dintero);
    const postPaymentEngineLogo = store.getters['appSettings/POST_PAYMENT_ENGINE_LOGO'];
    const newsletterAccepted = store.getters['customerPreferences/NEWSLETTER_CONSENT'];

    const formValues = reactive({
        amount: helpers.formatMoney(BALANCE_AMOUNT),
        email: emailFieldAvailable.value ? store.getters['customerPreferences/RECENT_MAILBOX'] : null,
        newsletterConsent: !newsletterAccepted
    });
    const validationRules = computed(() => ({
        amount: {
            required,
            betweenWithFormat: and(between(0.01, BALANCE_AMOUNT), helpers.onlyTwoDigits)
        },
        email: emailFieldAvailable.value ? { required, email } : {},
        newsletterConsent: {}
    }));
    const v$ = useVuelidate(validationRules, formValues);

    const submitForm = () => {
        v$.value.$touch();
        if (!v$.value.$invalid) {
            emit('onSubmit', formValues);
        }
    };
</script>
