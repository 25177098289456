const formatMoney = (value) => {
    return Number.parseFloat(value).toFixed(2);
};

const onlyTwoDigits = (value) =>
    isNaN(value) || isNaN(parseFloat(value)) || new RegExp(`^(\\d+([\\.\\,]\\d{1,2})?|[\\.\\,]\\d{1,2})$`).test(value);

export default {
    formatMoney,
    onlyTwoDigits
};
