<template>
    <div class="page-wrapper">
        <Spinner v-if="BALANCE_LOADING" :fullScreen="true" />

        <PageSection v-else>
            <PaymentInitiationForm
                v-if="BALANCE_AMOUNT > 0"
                @onSubmit="INITIATE_PAYMENT($event)"
                :disableForm="!!NETS_PAYMENT_ID || !!DINTERO_PAYMENT_ID"
            />
            <h3 v-else class="my-3 test-no-unpaid">{{ t('postpay_overview.no_debt') }}</h3>
        </PageSection>

        <PageSection v-if="NETS_PAYMENT_ID">
            <h1>{{ t('postpay_overview.checkout_title') }}</h1>
            <NetsEmbeddedCheckout
                :paymentId="NETS_PAYMENT_ID"
                :scrollIntoView="true"
                @onSuccess="onNetsPaymentSuccess()"
            />
        </PageSection>

        <PageSection v-if="DINTERO_PAYMENT_ID">
            <DinteroEmbeddedCheckout
                :paymentId="DINTERO_PAYMENT_ID"
                :heading="t('postpay_overview.checkout_title')"
                :scrollIntoView="true"
            />
        </PageSection>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import Spinner from '../../components/Spinner.vue';
    import NetsEmbeddedCheckout from '../../components/NetsEmbeddedCheckout/NetsEmbeddedCheckout.vue';
    import DinteroEmbeddedCheckout from '../../components/DinteroEmbeddedCheckout/DinteroEmbeddedCheckout.vue';
    import PaymentInitiationForm from './PaymentInitiationForm.vue';
    import PageSection from '../../components/PageSection.vue';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    store.dispatch('pagePostpayOverview/ON_PAGE_LOAD');
    store.dispatch('balancePostpay/LOAD_BALANCE');

    const onNetsPaymentSuccess = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
        router.push({
            name: 'Route_PostpaySuccess',
            params: { paymentId: store.getters['pagePostpayOverview/NETS_PAYMENT_ID'] }
        });
    };

    const BALANCE_LOADING = computed(() => store.getters['balancePostpay/BALANCE_LOADING']);
    const BALANCE_AMOUNT = computed(() => store.getters['balancePostpay/BALANCE_AMOUNT']);
    const NETS_PAYMENT_ID = computed(() => store.getters['pagePostpayOverview/NETS_PAYMENT_ID']);
    const DINTERO_PAYMENT_ID = computed(() => store.getters['pagePostpayOverview/DINTERO_PAYMENT_ID']);
    const INITIATE_PAYMENT = ({ amount, email, newsletterConsent }) => {
        store.dispatch('pagePostpayOverview/INITIATE_PAYMENT', { amount, email });
        store.dispatch('customerPreferences/UPDATE_CUSTOMER_PREFERENCES', { email, newsletterConsent });
    };
</script>
